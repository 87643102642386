const routes = Object.freeze({
  notFoundRoute: '/404',
  tagRoute: '/tag',
  topicRoute: '/topic',
  eventRoute: '/event',
  indexRoute: '',
  paperRoute: '/paper',
  postRoute: '/post',
  dialogRoute: '/dialog',
  interviewRoute: '/interview',
  pageRoute: '',
  projectRoute: '/success-stories',
  internalPageRoute: '',
  personRoute: '/profile',
  episodeRoute: '/podcast',
  reportRoute: '',
  visionRoute: '',
  testRoute: '/_test',
  dialogsRoute: '/thought-leadership/dialogs',
  papersRoute: '/thought-leadership/papers',
  postsRoute: '/thought-leadership/posts',
  interviewsRoute: '/thought-leadership/interviews',
  projectsRoute: '/success-stories',
  episodesRoute: '/thought-leadership/podcasts',
  eventsRoute: '/thought-leadership/events',
  apiTriggerSyncAirtableRoute: '/api/trigger-sync-airtable/',
  apiWebhookSyncAirtableRoute: '/api/webhook-sync-airtable/',
  apiFormRoute: '/api/gate/',
  apiFormSaveRoute: '/api/gate-save/',
  apiSubscriptionRoute: '/api/newsletter-subscription/',
  apiSubscriptionSaveRoute: '/api/newsletter-subscription-save/',
  apiSubscriptionConfirmationRoute: '/api/subscription-confirmation/',
  subscriptionConfirmationSuccessRoute: '/thank-you/',
  productionRoute: 'https://insurance.nttdata.com',
  datocmsRoute: 'https://insurance.admin.datocms.com',
  datocmsEditorTypesRoute:
    'https://insurance.admin.datocms.com/editor/item_types',
  podcastPlayerPrefix: 'https://share.transistor.fm/e/'
})

export default routes
