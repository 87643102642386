import slugify from 'slugify'

import { config } from '@/internal/config/config'
import routes from '@/internal/constants/routes'

type TypeName =
  | 'DatoCmsPctPaper'
  | 'DatoCmsPctPost'
  | 'DatoCmsPctInterview'
  | 'DatoCmsPctDialog'
  | 'DatoCmsPctPage'
  | 'DatoCmsPctTag'
  | 'DatoCmsPctTopic'
  | 'DatoCmsPctProject'
  | 'DatoCmsPctInternalPage'
  | 'DatoCmsPctEpisode'
  | 'DatoCmsGctPerson'
  | 'DatoCmsPctVision'
  | 'DatoCmsPctReport'
  | 'DatoCmsPctExternalContent'
  | 'DatoCmsPctEvent'

export function convertModelToType(model: string): TypeName {
  const relation: Record<string, TypeName> = {
    pct_paper: 'DatoCmsPctPaper',
    pct_post: 'DatoCmsPctPost',
    pct_interview: 'DatoCmsPctInterview',
    pct_dialog: 'DatoCmsPctDialog',
    pct_page: 'DatoCmsPctPage',
    pct_tag: 'DatoCmsPctTag',
    pct_topic: 'DatoCmsPctTopic',
    pct_project: 'DatoCmsPctProject',
    pct_internal_page: 'DatoCmsPctInternalPage',
    pct_episode: 'DatoCmsPctEpisode',
    gct_person: 'DatoCmsGctPerson',
    pct_vision: 'DatoCmsPctVision',
    pct_report: 'DatoCmsPctReport',
    pct_external_content: 'DatoCmsPctExternalContent',
    pct_event: 'DatoCmsPctEvent'
  }
  return relation[model]
}
export function generateUrl(
  type: TypeName,
  slug: null | string | (null | string)[] = '',
  absolute = false,
  hashValue = ''
): string {
  if (type === 'DatoCmsPctExternalContent') {
    return Array.isArray(slug) ? slug[0] ?? '' : slug ?? ''
  }

  const url =
    typeof slug === 'string'
      ? slug
      : Array.isArray(slug)
        ? slugify(slug.join(' '), {
            lower: true,
            strict: true,
            remove: /[*+~.()'"!:@]/g
          })
        : ''

  if (url === '') {
    return ''
  }

  const routesPrefix: Record<string, string> = {
    DatoCmsPctPaper: routes.paperRoute,
    DatoCmsPctPost: routes.postRoute,
    DatoCmsPctInterview: routes.interviewRoute,
    DatoCmsPctDialog: routes.dialogRoute,
    DatoCmsPctPage: routes.pageRoute,
    DatoCmsPctTag: routes.tagRoute,
    DatoCmsPctTopic: routes.topicRoute,
    DatoCmsPctProject: routes.projectRoute,
    DatoCmsPctInternalPage: routes.internalPageRoute,
    DatoCmsPctEpisode: routes.episodeRoute,
    DatoCmsGctPerson: routes.personRoute,
    DatoCmsPctVision: routes.visionRoute,
    DatoCmsPctReport: routes.reportRoute,
    DatoCmsPctEvent: routes.eventRoute
  }

  const hash = hashValue ? `#${hashValue}` : ''
  const prefix = type ? routesPrefix[type] ?? '' : ''

  return `${absolute ? config.app.url : ''}${prefix}/${url}/${hash}`
}

export const getAbsoluteUrl = (siteUrl: string, pathname: string): string =>
  `${siteUrl}${pathname}`
